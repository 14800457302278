// @flow
import React, { memo } from 'react'
import Icon from '@renderforest/rf-ui-library/atoms/Icon'
import * as Styled from './styled'

const IconButton = ({ iconName, text, onClick, hasOtherStyle }) => (
  <Styled.IconButton onClick={onClick} hasOtherStyle={hasOtherStyle}>
    <Icon name={iconName} />
    <Styled.ButtonText>{text}</Styled.ButtonText>
  </Styled.IconButton>
)

export default memo(IconButton)
