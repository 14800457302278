import styled from 'styled-components'

export const SuccessStateContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 12;
  background: rgba(255, 255, 255, 0.7);

  p {
    font-size: 16px;
    font-weight: 500;
    color: #545f7e;
  }

  img {
    animation: scale-image 700ms ease-out;
    @keyframes scale-image {
      0% {
        transform: scale(0.5);
      }
      90% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    }
  }
`
