// @flow
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'
import { transformTextVariables } from '@src/editor/common/utils/text-transformation'
import { BoldComp } from '@editor/common/styled-components/simple-tags'

import * as Styled from './styled'
import type { TLiveChatsProps } from './types'
import { MainContent } from '../../styled'
import { LIVE_CHATS, LIVE_CHATS_META } from './consts'
import { reorderLiveChats } from './utils'
import LiveChatItem from './LiveChatItem'
import { selectChatSettings, selectGDPRCookies } from '@selectors'

const NOTIFICATION_MESSAGE = transformTextVariables(
  translate('livechat_notification_label'),
  [
    {
      value: translate('get_link_to_invite_contributor_note_tr1'),
      Comp: BoldComp
    }
  ]
)

const LiveChats = ({ liveChatSettings, isGDPRVisible }: TLiveChatsProps) => {
  const [liveChats, setLiveChatsOrder] = useState(LIVE_CHATS)

  const { data } = liveChatSettings
  const hasPrimaryChat = data.findIndex(settings => settings.isPrimary) !== -1
  useEffect(() => {
    const reorderedLiveChatsData = reorderLiveChats(data)

    setLiveChatsOrder(reorderedLiveChatsData)
  }, [])

  return (
    <div>
      <Styled.Title>{translate('provide_superior_service_label')}</Styled.Title>
      {isGDPRVisible ? (
        <Styled.NotificationMessage message={NOTIFICATION_MESSAGE} />
      ) : null}
      <MainContent>
        {liveChats.map(liveChatName => {
          const chatMeta = LIVE_CHATS_META.find(
            meta => meta.type === liveChatName
          )
          const singleChatSettings = data.find(
            settings => settings.name === liveChatName
          )

          return (
            <LiveChatItem
              key={chatMeta.type}
              hasPrimaryChat={hasPrimaryChat}
              meta={chatMeta}
              data={singleChatSettings}
            />
          )
        })}
      </MainContent>
    </div>
  )
}

const mapStateToProps = state => ({
  liveChatSettings: selectChatSettings(state),
  isGDPRVisible: selectGDPRCookies(state)?.isVisible
})

export default connect(mapStateToProps)(LiveChats)
