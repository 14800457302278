// @flow

import React, { memo } from 'react'
import * as Styled from './styled'
import Messenger from '@website/common/components/Messenger'

const Preview = ({ messengerType, data }) => (
  <Styled.PreviewWrapper>
    <Messenger isOpen isForPreview type={messengerType} data={data} />
  </Styled.PreviewWrapper>
)

export default memo(Preview)
