// @flow

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch
} from 'react-router-dom'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import { TABS_DATA } from './consts'
import { Messengers, LiveChats } from './contents'
import { Tabs } from '@editor/common/components/Tabs'
import { useHistoryWithSearch } from '@hooks'
import { selectWidgetsLoadingState } from '@selectors'
import { RfLoader } from '@editor/common/components/Loaders'
import SuccessState from './contents/shared-components/SuccessState'
import { PAGE_BASE } from '@editor/conf/routes'

const getActiveTab = pathname => pathname.split('/')[6]

const Widgets = ({ isConnecting }) => {
  const [showSuccess, setSuccessState] = useState(false)

  const { url, path } = useRouteMatch(PAGE_BASE)
  const history = useHistoryWithSearch()
  const { pathname, search } = useLocation()
  const contentRef = useRef(null)
  const activeTab = getActiveTab(pathname)

  useEffect(() => {
    const { current: elem } = contentRef

    elem &&
      elem.scrollTo({
        top: 0
      })
  }, [contentRef, activeTab])

  const setActiveTab = useCallback(
    activeTab => {
      history.push({
        pathname: `${url}/widgets/${activeTab}`,
        search
      })
    },
    [url]
  )

  const onClose = useCallback(() => {
    history.push(url)
  }, [url])

  return (
    <Styled.WidgetsPopup isOpen={true} onClose={onClose} isMobileFullScreen>
      <RfLoader
        isVisible={isConnecting}
        text={translate('saving_your_changes_label')}
        className="saving-loader"
      />
      {showSuccess ? <SuccessState /> : null}
      <Styled.Title>{translate('widget_settings_label')}</Styled.Title>
      <Styled.TabsWrapper>
        <Tabs
          activeTab={activeTab}
          data={TABS_DATA}
          setActiveTab={setActiveTab}
        />
      </Styled.TabsWrapper>
      <Styled.Content ref={contentRef}>
        <Switch>
          <Route
            exact
            path={`${path}/widgets/messengers`}
            render={props => (
              <Messengers
                {...props}
                showSuccess={showSuccess}
                setSuccessState={setSuccessState}
              />
            )}
          />
          <Route
            path={`${path}/widgets/live-chats`}
            render={props => <LiveChats {...props} />}
          />
          <Redirect to={`${path}/widgets/messengers${search}`} />
        </Switch>
      </Styled.Content>
    </Styled.WidgetsPopup>
  )
}

const mapStateToProps = state => ({
  isConnecting: selectWidgetsLoadingState(state)
})

export default connect(mapStateToProps)(Widgets)
