// @flow
import React, { memo, useCallback } from 'react'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import type { TButtonsProps } from './types'
import { ActionButtons, Button, ButtonWithIcon } from '../../../styled'
import CustomizeIcon from '@editor/common/assets/svgr-icons/Customize_icon.svg'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon.svg'

const language = window.CURRENT_LANGUAGE

const Buttons = ({
  hasChange,
  disabled,
  isConnected,
  isAddedToWebsite,
  messengerType,
  setMessengerType,
  onConnect,
  onAddToWebsite,
  openCustomizeWindow,
  onRemoveFromWebsite
}: TButtonsProps) => {
  const onCustomizeButtonClick = useCallback(() => {
    setMessengerType(messengerType)
    openCustomizeWindow()
  }, [messengerType])
  const customizationIcon = language === 'de' ? null : <CustomizeIcon />

  return (
    <ActionButtons>
      {!isConnected || hasChange ? (
        <Button disabled={disabled} onClick={onConnect}>
          {translate('connect_label')}
        </Button>
      ) : isAddedToWebsite ? (
        <>
          <ButtonWithIcon onClick={onCustomizeButtonClick}>
            {customizationIcon}
            {translate('customize_label')}
          </ButtonWithIcon>
          <Styled.Divider />
          <ButtonWithIcon onClick={onRemoveFromWebsite}>
            <DeleteIcon />
            {translate('remove_label')}
          </ButtonWithIcon>
        </>
      ) : (
        <>
          <ButtonWithIcon onClick={onCustomizeButtonClick}>
            {customizationIcon}
            {translate('customize_label')}
          </ButtonWithIcon>
          <Styled.Divider />
          <Button onClick={onAddToWebsite}>
            {translate('add_to_website_label')}
          </Button>
        </>
      )}
    </ActionButtons>
  )
}

export default memo(Buttons)
