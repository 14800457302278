import { translate } from '@editor/common/utils/translations'

export const EMPTY_MESSENGER_DATA = {
  name: '',
  message: '',
  buttonText: ''
}

export const INITIAL_VALIDATION_STATES = {
  name: true,
  message: true,
  buttonText: true
}

export const AVATAR_CROPPER_DEFAULT_PROPS = {
  isOpen: true,
  showReplace: false,
  containerWidth: 120,
  height: 120,
  cropperType: 'circle'
}

export const REQUIRED_FIELD_MESSAGE = translate(
  'this_field_cannot_be_empty_label'
)

export const MAX_CHAR_LIMITS = {
  name: 150,
  subtitle: 150,
  message: 1100,
  buttonText: 70
}

export const INITIAL_CHAR_LIMIT_MESSAGES = {
  name: '',
  subtitle: '',
  message: '',
  buttonText: ''
}
