// @flow
import React, { useCallback, memo } from 'react'

import * as Styled from './styled'
import type { TTextInputProps } from './types'
import Input from '@website/common/components/Input'

const TextInput = (props: TTextInputProps) => {
  const { onChange } = props

  const _onChange = useCallback(
    e => {
      onChange(e.target.value)
    },
    [onChange]
  )

  return (
    <Styled.TextInputWrapper>
      <Input {...props} type="text" onChange={_onChange} />
    </Styled.TextInputWrapper>
  )
}

export default memo(TextInput)
