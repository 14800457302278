// @flow

import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'
import { transformTextVariables } from '@src/editor/common/utils/text-transformation'

import Input from '@renderforest/rf-ui-library/molecules/Input'
import ImageChooser from './ImageChooser'
import Preview from '../../../components/Preview'
import * as Styled from './styled'
import {
  EMPTY_MESSENGER_DATA,
  INITIAL_VALIDATION_STATES,
  REQUIRED_FIELD_MESSAGE,
  INITIAL_CHAR_LIMIT_MESSAGES,
  MAX_CHAR_LIMITS
} from '../consts'
import { isEqual, validateData } from './utils'
import { omit } from '@editor/common/utils'
import { leftJoin } from '@editor/common/utils'

import { setMessengerData } from '@actions/widgets'
import { selectImagesUploadInfo } from '@selectors'

const getCharMaxLimitMessage = count =>
  transformTextVariables(translate('max_char_message'), [{ value: count }])

const EditingForm = ({
  type,
  data: dataFromProps,
  imagesUploadInfo,
  isSaving,
  onSave,
  onClose
}) => {
  const initialData = {
    ...EMPTY_MESSENGER_DATA,
    ...dataFromProps
  }

  const [data, setData] = useState(initialData)
  const [inputValidStates, setInputValidStates] = useState(
    INITIAL_VALIDATION_STATES
  )
  const [charLimitMessages, setCharLimitMessages] = useState(
    INITIAL_CHAR_LIMIT_MESSAGES
  )

  const hasChanges = isEqual(initialData, data)
  const dataWithImgUploadInfo = leftJoin(data, imagesUploadInfo)

  const setValidState = useCallback(newValidStates => {
    setInputValidStates({
      ...inputValidStates,
      ...newValidStates
    })
  }, [])

  const _setData = key => e => {
    const value = e.target ? e.target.value : e
    const maxCharLimit = MAX_CHAR_LIMITS[key]
    if (maxCharLimit && value.length > maxCharLimit) {
      const charLimitMessage = getCharMaxLimitMessage(maxCharLimit)
      setCharLimitMessages({
        ...charLimitMessages,
        [key]: charLimitMessage
      })
      return
    }
    setCharLimitMessages(INITIAL_CHAR_LIMIT_MESSAGES)
    setData(state => {
      return {
        ...state,
        [key]: value
      }
    })
    if (INITIAL_VALIDATION_STATES.hasOwnProperty(key)) {
      setValidState({ [key]: true })
    }
  }

  const resetCharLimitMessages = useCallback(() => {
    setCharLimitMessages(INITIAL_CHAR_LIMIT_MESSAGES)
  }, [])

  const handleSubtitleChange = useCallback(
    e => {
      const value = e.target ? e.target.value : e
      if (value.length > MAX_CHAR_LIMITS.subtitle) {
        const charLimitMessage = getCharMaxLimitMessage(
          MAX_CHAR_LIMITS.subtitle
        )

        setCharLimitMessages({
          ...charLimitMessages,
          subtitle: charLimitMessage
        })
        return
      }
      setCharLimitMessages(INITIAL_CHAR_LIMIT_MESSAGES)
      setData(state =>
        !value ? omit(state, ['subtitle']) : { ...state, subtitle: value }
      )
    },
    [charLimitMessages]
  )

  const handleAvatarChange = useCallback((url, optimizedUrl) => {
    _setData('avatar')(url)
    _setData('optimizedAvatar')(optimizedUrl || '')
  })

  const handleAvatarRemove = useCallback(() => {
    setData(state => {
      const { avatar, imgCropParams, ...rest } = state
      return rest
    })
  }, [])

  const handleSave = useCallback(() => {
    if (!validateData(data, setValidState) || isSaving) {
      return
    }

    onSave(type, data)
  }, [data, isSaving, onSave])

  const isAvatarLoading =
    dataWithImgUploadInfo.avatar && dataWithImgUploadInfo.avatar.isLoading

  return (
    <>
      <Styled.EditingForm>
        <Styled.Containers>
          <Styled.Container>
            <ImageChooser
              imageUrl={dataWithImgUploadInfo.avatar}
              optimizedImageUrl={dataWithImgUploadInfo.optimizedAvatar}
              imgCropParams={data.imgCropParams}
              onChoose={handleAvatarChange}
              onRemove={handleAvatarRemove}
              setImgUrl={_setData('avatar')}
              setImgCropParams={_setData('imgCropParams')}
            />
            <Styled.FormWrapper>
              <Styled.InputWrapper>
                <Input
                  error={
                    charLimitMessages.name ||
                    (inputValidStates.name ? '' : REQUIRED_FIELD_MESSAGE)
                  }
                  label={translate('name_label')}
                  onBlur={resetCharLimitMessages}
                  onChange={_setData('name')}
                  value={dataWithImgUploadInfo.name}
                />
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <Input
                  error={charLimitMessages.subtitle}
                  label={translate('subtitle_label')}
                  onBlur={resetCharLimitMessages}
                  onChange={handleSubtitleChange}
                  value={dataWithImgUploadInfo.subtitle}
                />
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <Input
                  error={
                    charLimitMessages.message ||
                    (inputValidStates.message ? '' : REQUIRED_FIELD_MESSAGE)
                  }
                  textArea
                  height={100}
                  label={translate('message_label')}
                  onBlur={resetCharLimitMessages}
                  onChange={_setData('message')}
                  value={dataWithImgUploadInfo.message}
                />
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <Input
                  error={
                    charLimitMessages.buttonText ||
                    (inputValidStates.buttonText ? '' : REQUIRED_FIELD_MESSAGE)
                  }
                  label={translate('button_text_label')}
                  onBlur={resetCharLimitMessages}
                  onChange={_setData('buttonText')}
                  value={dataWithImgUploadInfo.buttonText}
                />
              </Styled.InputWrapper>
            </Styled.FormWrapper>
          </Styled.Container>
          <Styled.Container>
            <Styled.PreviewContainer>
              <Preview messengerType={type} data={dataWithImgUploadInfo} />
            </Styled.PreviewContainer>
          </Styled.Container>
        </Styled.Containers>
      </Styled.EditingForm>
      <Styled.ButtonsWrapper>
        <Styled.Button
          onClick={handleSave}
          disabled={!hasChanges || isAvatarLoading}
        >
          {translate('save_label', true)}
        </Styled.Button>
        <Styled.SecondaryButton onClick={onClose}>
          {translate('cancel_label')}
        </Styled.SecondaryButton>
      </Styled.ButtonsWrapper>
    </>
  )
}

const mapDispatchToProps = {
  onSave: setMessengerData
}

const mapStateToProps = state => ({
  imagesUploadInfo: selectImagesUploadInfo(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(EditingForm)
