// @flow

import { MESSENGERS } from './consts'

export const extractNumberFromString = (str: string) => {
  return parseInt(str.match(/\d/g).join(''))
}

export const reorderMessengers = messengerSettings => {
  if (messengerSettings.length === 0) {
    return MESSENGERS
  }
  const reorderedMessengers = []
  const restMessengers = []

  MESSENGERS.forEach(messenger => {
    const singleMessengerSettings = messengerSettings.find(
      setting => setting.name === messenger
    )
    if (!singleMessengerSettings) {
      restMessengers.push(messenger)
      return
    }
    const isMessengerActive = singleMessengerSettings.isActive

    if (isMessengerActive) {
      reorderedMessengers.unshift(messenger)
    } else {
      reorderedMessengers.push(messenger)
    }
  })

  return [...reorderedMessengers, ...restMessengers]
}
