import styled from 'styled-components'

import { ContentTitle } from '../../styled'
import LibNotificationMessage from '@editor/common/lib/NotificationMessage'

export const Title = styled(ContentTitle)`
  max-width: 460px;
  padding-bottom: 30px;

  @media only screen and (max-width: 768px) {
    padding: 0 20px 30px;
  }
`

export const NotificationMessage = styled(LibNotificationMessage)`
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 30px;
  max-width: 720px;
  svg {
    width: 14px;
  }

  @media only screen and (max-width: 767px) {
    width: 350px;
    margin: 0 auto 30px;
  }
`
