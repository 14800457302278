// @flow

import React, { useState, useCallback, useContext } from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import { ImageHolder } from './consts'
import { AVATAR_CROPPER_DEFAULT_PROPS } from '../../consts'
import { MODAL_TYPES } from '@website/common/components/Controlled/ControlModals/consts'
import AddIcon from '@editor/common/assets/svgr-icons/Plus_icon.svg'
import EditIcon from '@editor/common/assets/svgr-icons/Edit_icon.svg'
import ReplaceIcon from '@editor/common/assets/svgr-icons/replace_icon.svg'
import Image from '@website/common/components/Image'
import ChooserFlyout from './ChooserFlyOut'
import { ControlModalsContext } from '@contexts'
import { selectIsUserAuthorized } from '@selectors'
import { openLoginPopup } from '@actions/editor'

const ImageChooser = ({
  imageUrl,
  optimizedImageUrl,
  imgCropParams,
  onChoose,
  onRemove,
  setImgCropParams,
  isUserAuthorized,
  openLoginPopup
}) => {
  const [isChooserOpen, setChooserOpenedState] = useState(false)
  const { setModalState } = useContext(ControlModalsContext)

  const hasImage = !!imageUrl
  const imgSizes = optimizedImageUrl
    ? {
        small: optimizedImageUrl,
        medium: optimizedImageUrl,
        large: optimizedImageUrl,
        xlarge: optimizedImageUrl
      }
    : undefined

  const openChooser = useCallback(() => {
    if (!isUserAuthorized) {
      openLoginPopup()
      return
    }
    setChooserOpenedState(true)
  }, [isUserAuthorized, openLoginPopup])

  const closeChooser = useCallback(() => {
    setChooserOpenedState(false)
  }, [setChooserOpenedState])

  const setCropperModalState = useCallback(
    data => {
      setModalState(MODAL_TYPES.imageCrop, data)
    },
    [setModalState]
  )

  const openCropper = useCallback(
    data => {
      setCropperModalState({
        ...AVATAR_CROPPER_DEFAULT_PROPS,
        ...data
      })
    },
    [setCropperModalState]
  )

  const handleEditClick = useCallback(() => {
    openCropper({
      imgCropParams,
      imgSrc: imageUrl,
      handler: imgCropParams => {
        setImgCropParams(imgCropParams)
      }
    })
  }, [openCropper, imgCropParams, imageUrl, setImgCropParams])

  return (
    <Styled.ImageChooserContainer>
      <Styled.ImageWrapper>
        {hasImage ? (
          <Image
            withLazy={false}
            as={Styled.Image}
            defaultImgSrc={imageUrl}
            sizes={imgSizes}
            alt="avatar"
            asProps={{ imgCropParams }}
          />
        ) : (
          <img src={ImageHolder} alt="avatar" />
        )}
      </Styled.ImageWrapper>
      <Styled.ImageActions>
        <Styled.Buttons>
          {hasImage ? (
            <>
              {optimizedImageUrl ? (
                <Styled.Button onClick={handleEditClick}>
                  <EditIcon className="edit-icon" />
                  {translate('edit_label')}
                </Styled.Button>
              ) : null}
              <ChooserFlyout
                isOpen={isChooserOpen}
                onClose={closeChooser}
                onChoose={onChoose}
                onRemove={onRemove}
              >
                <Styled.SecondaryButton onClick={openChooser}>
                  <ReplaceIcon />
                  {translate('replace_label')}
                </Styled.SecondaryButton>
              </ChooserFlyout>
            </>
          ) : (
            <ChooserFlyout
              isOpen={isChooserOpen}
              onClose={closeChooser}
              onChoose={onChoose}
            >
              <Styled.Button onClick={openChooser}>
                <AddIcon />
                {translate('add_image_label')}
              </Styled.Button>
            </ChooserFlyout>
          )}
        </Styled.Buttons>
        <Styled.SizeRecommendationText>
          <b>{translate('recommended_size_label')}</b> 400 x 400 px
        </Styled.SizeRecommendationText>
      </Styled.ImageActions>
    </Styled.ImageChooserContainer>
  )
}

const mapStateToProps = state => ({
  isUserAuthorized: selectIsUserAuthorized(state)
})

export default connect(mapStateToProps, { openLoginPopup })(ImageChooser)
