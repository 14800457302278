import { TextInput } from './components'
import PhoneInput from '@website/common/components/PhoneInput'
import { translate } from '@editor/common/utils/translations'

const MESSENGER_LOGO =
  'https://static.rfstat.com/renderforest/images/website_maker_images/messenger-logo.svg'
const TELEGRAM_LOGO =
  'https://static.rfstat.com/renderforest/images/website_maker_images/telegram-logo.svg'
const WHATSAPP_LOGO =
  'https://static.rfstat.com/renderforest/images/website_maker_images/whatsapp-logo.svg'
const VIBER_LOGO =
  'https://static.rfstat.com/renderforest/images/website_maker_images/viber-logo.svg'

export const MESSENGERS = ['facebook', 'whatsapp', 'telegram', 'viber']
export const MESSENGERS_META = [
  {
    type: 'facebook',
    title: 'Messenger',
    logo: MESSENGER_LOGO,
    inputLabel: translate('enter_fb_messenger_link_label'),
    Input: TextInput,
    validation: {
      validate: value => {
        const regExp1 = new RegExp(
          /^(https?:\/\/)?(www\.)?messenger.com\/t\/[a-zA-Z0-9(\.\?)?]/
        )
        const regExp2 = new RegExp(/^(https?:\/\/)?m.me\/[a-zA-Z0-9(\.\?)?]/)
        return regExp1.test(value) || regExp2.test(value)
      },
      message: translate('please_enter_valid_message_link_label')
    },
    maxCharLimit: 100
  },
  {
    type: 'whatsapp',
    title: 'WhatsApp',
    logo: WHATSAPP_LOGO,
    inputLabel: translate('enter_phone_number_label'),
    Input: PhoneInput,
    maxCharLimit: 20
  },
  {
    type: 'telegram',
    title: 'Telegram',
    logo: TELEGRAM_LOGO,
    inputLabel: translate('enter_username_label'),
    Input: TextInput,
    maxCharLimit: 50
  },
  {
    type: 'viber',
    title: 'Viber',
    logo: VIBER_LOGO,
    inputLabel: translate('enter_phone_number_label'),
    Input: PhoneInput,
    maxCharLimit: 20
  }
]
