import { LIVE_CHATS } from './consts'

export const reorderLiveChats = chatsSettings => {
  if (chatsSettings.length === 0) {
    return LIVE_CHATS
  }

  const reorderedChats = []
  const notConnectedChats = []

  LIVE_CHATS.forEach(chatName => {
    const singleChatSettings = chatsSettings.find(
      chat => chat.name === chatName
    )

    if (!singleChatSettings) {
      notConnectedChats.push(chatName)
      return
    }
    if (singleChatSettings.isPrimary) {
      reorderedChats.unshift(chatName)
      return
    }
    reorderedChats.push(chatName)
  })
  return [...reorderedChats, ...notConnectedChats]
}
