// @flow

import React from 'react'
import { translate } from '@editor/common/utils/translations'

const FRESH_CHAT_LOGO =
  'https://static.rfstat.com/renderforest/images/website_maker_images/freshchat.svg'
const LIVE_CHAT_LOGO =
  'https://static.rfstat.com/renderforest/images/website_maker_images/livechat.svg'
const INTERCOM_LOGO =
  'https://static.rfstat.com/renderforest/images/website_maker_images/intercom.svg'
const JIVOCHAT_LOGO =
  'https://static.rfstat.com/renderforest/images/website_maker_images/jivochat.svg'
const OLARK_LOGO =
  'https://static.rfstat.com/renderforest/images/website_maker_images/olark.svg'

const findId = (target, regexp) => {
  return (target.match(regexp) || [])[1]
}

export const LIVE_CHATS = [
  'livechat',
  'intercom',
  'freshchat',
  'jivochat',
  'olark'
]

export const LIVE_CHATS_META = [
  {
    type: 'livechat',
    title: 'LiveChat',
    helpLink:
      'https://www.livechat.com/help/add-livechat-to-your-renderforest-website/?utm_source=renderforest.com&utm_medium=referral&utm_campaign=renderforest_integration',
    logo: LIVE_CHAT_LOGO,
    logoHeight: 24,
    inputLabel: translate('enter_your_chatid_label'),
    embedFormatter: (embedValue: string): string => {
      const regExp = new RegExp(/window\.__lc\.license = ([0-9]+);/, 'i')
      return findId(embedValue, regExp)
    },
    validator: (value: string): boolean => {
      const regExp = new RegExp(/^([0-9]{7,8})$/)
      return regExp.test(value)
    },
    validationMessage: translate('please_enter_valid_chatid_label')
  },
  {
    type: 'intercom',
    title: 'Intercom',
    helpLink:
      'https://www.intercom.com/help/en/articles/167-install-intercom-on-your-website-for-logged-out-visitors',
    logo: INTERCOM_LOGO,
    inputLabel: translate('enter_your_appid_label'),
    embedFormatter: (embedValue: string): string => {
      const regExp = new RegExp(/app_id: "([a-zA-Z0-9]*)"/, 'i')
      return findId(embedValue, regExp)
    },
    validator: (value: string): boolean => {
      const regExp = new RegExp(/^[A-Za-z0-9]{8}$/)
      return regExp.test(value)
    },
    validationMessage: translate('please_enter_valid_appid_label')
  },
  {
    type: 'freshchat',
    title: 'Freshchat',
    helpLink:
      'https://support.freshchat.com/support/solutions/articles/50000000051-install-freshchat',
    logo: FRESH_CHAT_LOGO,
    inputLabel: translate('enter_your_token_label'),
    embedFormatter: (embedValue: string): string => {
      const regExp = new RegExp(/token: "([a-zA-Z0-9-]*)"/, 'i')
      return findId(embedValue, regExp)
    },
    validator: (value: string): boolean => {
      const regExp = new RegExp(
        /^[A-Za-z0-9]{8}-([A-Za-z0-9]{4}-){3}[A-Za-z0-9]{12}$/
      )
      return regExp.test(value)
    },
    validationMessage: translate('please_enter_valid_token_label')
  },
  {
    type: 'jivochat',
    title: 'Jivochat',
    helpLink: 'https://www.jivochat.com/help/installation/find-code.html',
    logo: JIVOCHAT_LOGO,
    inputLabel: translate('enter_your_chatid_label'),
    embedFormatter: (embedValue: string): string => {
      const regExp = new RegExp(
        /src="\/\/code.*\.jivosite\.com\/widget\/([a-zA-Z0-9]*)"/,
        'i'
      )
      return findId(embedValue, regExp)
    },
    validator: (value: string): boolean => {
      const regExp = new RegExp(/^[A-Za-z0-9]{10}$/)
      return regExp.test(value)
    },
    validationMessage: translate('please_enter_valid_chatid_label')
  },
  {
    type: 'olark',
    title: 'Olark',
    helpLink: 'https://www.olark.com/help/html/',
    logo: OLARK_LOGO,
    inputLabel: translate('enter_your_chatid_label'),
    embedFormatter: (embedValue: string): string => {
      const regExp = new RegExp(/olark\.identify\('([0-9-]*)'\);/, 'i')
      return findId(embedValue, regExp)
    },
    validator: (value: string): boolean => {
      const regExp = new RegExp(/^[0-9]{4}-[0-9]{3}-[0-9]{2}-[0-9]{4}$/)
      return regExp.test(value)
    },
    validationMessage: translate('please_enter_valid_chatid_label')
  }
]

export const EMPTY_SINGLE_CHAT_DATA = {
  key: '',
  isPrimary: false
}
