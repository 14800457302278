// @flow

import React, { memo, useCallback } from 'react'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import type { TAlignmentSwitcherProps } from './types'
import { LEFT, RIGHT } from './consts'

const AlignmentSwitcher = ({
  alignment,
  onChange
}: TAlignmentSwitcherProps) => {
  const handleChange = useCallback(
    value => {
      onChange(value)
    },
    [onChange]
  )

  return (
    <Styled.SwitcherWrapper>
      <Styled.SwitcherText>
        {translate('position_on_website_label')}
      </Styled.SwitcherText>
      <Styled.Switcher>
        <Styled.SwitcherButton
          isActive={alignment === LEFT}
          onClick={() => handleChange(LEFT)}
        >
          {translate('left_label')}
        </Styled.SwitcherButton>
        <Styled.SwitcherButton
          isActive={alignment === RIGHT}
          onClick={() => handleChange(RIGHT)}
        >
          {translate('right_label')}
        </Styled.SwitcherButton>
        <Styled.SwitcherButtonActive activeAlignment={alignment} />
      </Styled.Switcher>
    </Styled.SwitcherWrapper>
  )
}

export default memo(AlignmentSwitcher)
