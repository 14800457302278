// @flow
import React, { memo, useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import {
  Card,
  Title,
  Label,
  Status,
  LogoCard,
  CardWrapper,
  MainContent,
  Button,
  ActionButtons
} from '../../styled'
import type { TLiveChatItem } from './types'
import Input from '@website/common/components/Input'

import { EMPTY_SINGLE_CHAT_DATA } from '../consts'
import { openUrl } from '@website/common/utils'
import QuestionIcon from '@editor/common/assets/svgr-icons/question_filled_icon.svg'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import { setChatKey, setChatPrimaryState } from '@actions/widgets'

const WithTooltip = memo(({ children, isDisabled }) =>
  isDisabled ? (
    <>
      <Styled.GlobalStyles />
      <TooltipWrapper
        position="top"
        text={translate('disabled_button_tooltip_text')}
        color="#363F5A"
        className="button-tooltip"
      >
        {children}
      </TooltipWrapper>
    </>
  ) : (
    children
  )
)

const LiveChatItem = ({
  meta,
  data = EMPTY_SINGLE_CHAT_DATA,
  hasPrimaryChat,
  setChatKey,
  setChatPrimaryState
}: TLiveChatItem) => {
  const {
    type,
    title,
    logo,
    inputLabel,
    helpLink,
    validator,
    embedFormatter,
    validationMessage: message
  } = meta
  const chatId = data.key

  const [value, setValue] = useState(chatId)
  const [validationMessage, setValidationMessage] = useState('')

  const hasChange = chatId !== value
  const isConnected = !!chatId

  const onChange = useCallback(e => {
    setValidationMessage('')
    setValue(e.target.value)
  }, [])

  const onBlur = useCallback(() => {
    if (!hasChange) {
      return
    }

    if (!value) {
      setChatKey(type, '')
    }
  }, [value, type, hasChange])

  const onPaste = useCallback(e => {
    e.preventDefault()
    const copiedText = e.clipboardData.getData('text')
    const formattedText = embedFormatter(copiedText)
    setValue(formattedText || copiedText)
  }, [])

  const onRemoveIconClick = useCallback(() => {
    setValidationMessage('')
    setValue('')
  }, [embedFormatter])

  const onConnect = useCallback(() => {
    if (!hasChange) {
      return
    }

    if (value && !validator(value)) {
      setValidationMessage(message)
      return
    }

    setChatKey(type, value)
  }, [value, hasChange, type])

  const onAddToWebsite = useCallback(() => {
    setChatPrimaryState(type, true)
  }, [type])

  const onRemoveFromWebsite = useCallback(() => {
    setChatPrimaryState(type, false)
  }, [type])

  const onKeyPress = e => {
    if (e.key === 'Enter') {
      onConnect()
      e.target.blur()
    }
  }

  return (
    <>
      <CardWrapper>
        {isConnected ? (
          <Status isActive={data.isPrimary}>
            <div className="status-circle" />
            {data.isPrimary
              ? translate('active_label')
              : translate('connected_label')}
          </Status>
        ) : null}
        <Card>
          <MainContent>
            <Title>{title}</Title>
            <Styled.InputContainer>
              <Label>{inputLabel}</Label>
              <Styled.InputWrapper>
                <Input
                  type="text"
                  value={value}
                  error={validationMessage}
                  onRemoveIconClick={onRemoveIconClick}
                  onChange={onChange}
                  onBlur={onBlur}
                  onPaste={onPaste}
                  onKeyPress={onKeyPress}
                />
                <TooltipWrapper
                  text={translate('need_help_label')}
                  color="#545F7E"
                >
                  <QuestionIcon
                    className="need-help-icon"
                    onClick={() => openUrl(helpLink, true)}
                  />
                </TooltipWrapper>
              </Styled.InputWrapper>
            </Styled.InputContainer>
          </MainContent>
          <ActionButtons>
            {!isConnected || hasChange ? (
              <Button disabled={!value} onClick={onConnect}>
                {translate('connect_label')}
              </Button>
            ) : data.isPrimary ? (
              <Styled.SecondaryButton onClick={onRemoveFromWebsite}>
                {translate('remove_from_website_label')}
              </Styled.SecondaryButton>
            ) : (
              <WithTooltip isDisabled={hasPrimaryChat}>
                <Button disabled={hasPrimaryChat} onClick={onAddToWebsite}>
                  {translate('add_to_website_label')}
                </Button>
              </WithTooltip>
            )}
          </ActionButtons>
        </Card>
        <LogoCard>
          <img src={logo} alt="live-chat" />
        </LogoCard>
      </CardWrapper>
    </>
  )
}

const mapDispatchToProps = {
  setChatKey,
  setChatPrimaryState
}

export default connect(null, mapDispatchToProps)(LiveChatItem)
