import { translate } from '@editor/common/utils/translations'

export const TABS_DATA = [
  {
    label: translate('messengers_label'),
    value: 'messengers'
  },
  {
    label: translate('live_chats_label'),
    value: 'live-chats'
  }
]
