// @flow

import React, { memo } from 'react'
import InfoIcon from '@editor/common/assets/svgr-icons/Info_icon.svg'
import AttentionIcon from '@editor/common/assets/svgr-icons/Attention_icon_red.svg'
import * as Styled from './styled'
import type { TNotificationMessageProps } from './types'

const ICONS_BY_TYPE = {
  info: InfoIcon,
  warning: AttentionIcon,
  error: AttentionIcon
}

const NotificationMessage = ({
  className = '',
  type = 'warning',
  message = ''
}: TNotificationMessageProps) => {
  const IconComp = ICONS_BY_TYPE[type]

  return (
    <Styled.NotificationMessageWrapper type={type} className={className}>
      <IconComp />
      <Styled.Message>{message}</Styled.Message>
    </Styled.NotificationMessageWrapper>
  )
}

export default memo(NotificationMessage)
