// @flow
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import { TITLE, MESSENGERS, MESSENGERS_META } from './consts'
import { ContentTitle, MainContent } from '../../styled'
import type { TMessengersProps } from './types'
import { reorderMessengers } from './utils'
import { AlignmentSwitcher, MessengerItem, CustomizeWindow } from './components'
import { selectMessengerSettings, selectWidgetsLoadingState } from '@selectors'
import { changeMessengersAlignment } from '@actions/widgets'
import { getCountriesList } from '@actions/editor'

const Messengers = ({
  showSuccess,
  isLoading,
  messengerSettings,
  setSuccessState,
  changeMessengersAlignment,
  getCountriesList
}: TMessengersProps) => {
  const [selectedMessenger, setSelectedMessenger] = useState('')
  const [isCustomizeWindowOpen, setCustomizeWindowOpenedState] = useState(false)
  const [messengers, setMessengersOrder] = useState(MESSENGERS)

  const { settings, data } = messengerSettings
  const selectedMessengerData = useMemo(() => {
    const selectedMessengerSettings = data.find(
      messenger => messenger.name === selectedMessenger
    )
    return selectedMessengerSettings && selectedMessengerSettings.data
  }, [data, selectedMessenger])

  useEffect(() => {
    const reorderedMessengersData = reorderMessengers(data)
    setMessengersOrder(reorderedMessengersData)
  }, [])

  useEffect(() => {
    getCountriesList()
  }, [])

  const openCustomizeWindow = useCallback(() => {
    setCustomizeWindowOpenedState(true)
  }, [])

  const closeCustomizeWindow = useCallback(() => {
    setCustomizeWindowOpenedState(false)
  }, [])

  return (
    <Styled.Messengers>
      <ContentTitle>{translate('widgets_messengers_title')}</ContentTitle>
      <AlignmentSwitcher
        alignment={settings.alignment}
        onChange={changeMessengersAlignment}
      />
      <MainContent>
        {messengers.map(messengerType => {
          const meta = MESSENGERS_META.find(
            messenger => messenger.type === messengerType
          )
          const singleMessengerSettings = data.find(
            setting => setting.name === messengerType
          )

          return (
            <MessengerItem
              key={messengerType}
              settings={singleMessengerSettings}
              meta={meta}
              setMessengerType={setSelectedMessenger}
              openCustomizeWindow={openCustomizeWindow}
            />
          )
        })}
      </MainContent>
      <CustomizeWindow
        type={selectedMessenger}
        isSaving={isLoading}
        isOpen={isCustomizeWindowOpen}
        showSuccess={showSuccess}
        data={selectedMessengerData}
        setSuccessState={setSuccessState}
        onClose={closeCustomizeWindow}
      />
    </Styled.Messengers>
  )
}

const mapStateToProps = state => ({
  messengerSettings: selectMessengerSettings(state),
  isLoading: selectWidgetsLoadingState(state)
})

const mapDispatchToProps = {
  changeMessengersAlignment,
  getCountriesList
}

export default connect(mapStateToProps, mapDispatchToProps)(Messengers)
