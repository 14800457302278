// @flow

const isRequired = value => !!value

const VALIDATIONS = {
  name: isRequired,
  message: isRequired,
  buttonText: isRequired
}

export const isEqual = (oldData, newData) =>
  JSON.stringify(newData) !== JSON.stringify(oldData)

export const validateData = (data, cb) => {
  const validStates = {}
  Object.keys(data).forEach(key => {
    const validationFn = VALIDATIONS[key]
    if (!validationFn) {
      return
    }
    const isValid = validationFn(data[key])
    if (!isValid) {
      validStates[key] = false
    }
  })
  cb && cb(validStates)
  return Object.keys(validStates).length === 0
}
