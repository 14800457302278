import styled from 'styled-components'

export const CustomizeWindow = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 11;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: calc(100% - 30px);
  height: calc(100% - 113px);
  border-left: 1px solid #dee3f0;
  box-shadow: -3px 9px 12px #387dff33;
  background-color: #ffffff;
  box-sizing: border-box;
  animation: ${({ isOpen }) => (isOpen ? 'slide-in 0.3s' : 'slide-out 0.3s')};

  @keyframes slide-in {
    0% {
      transform: translateX(100%);
    }
  }

  @keyframes slide-out {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }

  @media only screen and (max-width: 768px) {
    @keyframes slide-up {
      0% {
        transform: translateY(100%);
      }
    }

    @keyframes slide-down {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(100%);
      }
    }

    width: 100%;
    height: calc(100% - 20px);
    border-radius: 10px 10px 0px 0px;
    animation: ${({ isOpen }) =>
      isOpen ? 'slide-up 0.3s' : 'slide-down 0.3s'};
  }
`

export const MobileCustomizeWindowWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 51;
  background-color: rgba(54, 63, 90, 0.5);
  .saving-loader {
    p {
      font-size: 16px;
      color: #545f7e;
    }
  }
`

export const Header = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #363f5a;
  padding: 20px 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #dde3f0;
  @media only screen and (max-width: 768px) {
    text-align: center;
  }
`
