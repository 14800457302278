import styled from 'styled-components'

import { LEFT } from './consts'
import { styleByLang } from '@editor/common/utils/style-by-lang'

const switcherCustomWidth = {
  sectionWidth: '185px',
  activeBtnWidth: '100px',
  activeBtnLeftAlgmt: '85px',
  switcherWidth: '140px'
}

const stylesObj = {
  pt: switcherCustomWidth,
  es: switcherCustomWidth,
  default: {
    sectionWidth: '135px',
    activeBtnWidth: '70px',
    activeBtnLeftAlgmt: '65px',
    switcherWidth: 'auto'
  }
}
const getStylesByLang = styleByLang(stylesObj)

export const SwitcherWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin: 20px 0;
  border-top: 1px solid #dee3f0;
  border-bottom: 1px solid #dee3f0;
  @media only screen and (max-width: 768px) {
    padding: 10px 0 10px 20px;
    margin: 20px 0 30px;
  }
`

export const Switcher = styled.div`
  position: relative;
  display: flex;
  border-radius: 18px;
  background: #ffffff;
  border: 1px solid #dee3f0;
  width: ${getStylesByLang('sectionWidth')};
  box-sizing: border-box;
  justify-content: space-between;
`
export const SwitcherButton = styled.div`
  position: relative;
  flex: 1;
  height: 36px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: ${({ isActive }) => (isActive ? '#387dff' : '#363f5a')};
  font-weight: ${({ isActive }) => (isActive ? 500 : 400)};
  border-radius: 8px;
  border: none;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`

export const SwitcherButtonActive = styled.div`
  width: ${getStylesByLang('activeBtnWidth')};
  height: 38px;
  border-radius: 18px;
  background-color: #eef5ff;
  position: absolute;
  top: -1px;
  left: ${({ activeAlignment }) =>
    activeAlignment === LEFT ? '-1px' : getStylesByLang('activeBtnLeftAlgmt')};
  z-index: 1;
  border: 1px solid #387dff;
  box-sizing: border-box;
  transition: left ease 0.3s;
`

export const SwitcherText = styled.p`
  font-size: 14px;
  color: #363f5a;
  margin-right: 10px;
  @media only screen and (max-width: 768px) {
    width: ${getStylesByLang('switcherWidth')};
  }
`
