// @flow

import { patchWidgetsDataAPI, deleteWidgetsDataAPI } from '@editor/api'
import { setHasChangesAfterPublish, triggerApiError } from '@actions/editor'
import { selectWidgets, getSiteId } from '@selectors'
import { hasChatSettings, hasMessengerSettings } from './utils'

const setLoadingState = (isLoading: boolean) => ({
  type: 'SET_WIDGETS_LOADING_STATUS',
  value: isLoading
})

export const patchOrRemoveWidgetsData = () => (dispatch, getState) => {
  const state = getState()
  const { data } = selectWidgets(state)
  const siteId = getSiteId(state)
  const _hasMessengerSettings = hasMessengerSettings(data.messengers)
  const _hasChatSettings = hasChatSettings(data.chats)

  const dataToSend = {}
  if (_hasMessengerSettings) {
    dataToSend.messengers = data.messengers
  }
  if (_hasChatSettings) {
    dataToSend.chats = data.chats
  }

  dispatch(setLoadingState(true))
  const reqPromise = !!Object.keys(dataToSend).length
    ? patchWidgetsDataAPI(siteId, dataToSend)
    : deleteWidgetsDataAPI(siteId)

  reqPromise
    .then(({ data }) => {
      dispatch(setHasChangesAfterPublish(data.hasChangesAfterPublish))
    })
    .catch(err => {
      dispatch(triggerApiError(err))
    })
    .finally(() => {
      dispatch(setLoadingState(false))
    })
}

export const changeMessengersAlignment =
  (alignment: 'left' | 'right') => (dispatch, getState) => {
    const { data } = selectWidgets(getState())

    dispatch({
      type: 'CHANGE_MESSENGERS_ALIGNMENT',
      value: alignment
    })

    if (!hasMessengerSettings(data.messengers)) {
      return
    }

    dispatch(patchOrRemoveWidgetsData())
  }

export const removeMessenger = (messengerName: string) => ({
  type: 'REMOVE_MESSENGER',
  value: messengerName
})

export const setMessengerValue =
  (messengerName: string, value: string) => dispatch => {
    if (!value) {
      dispatch(removeMessenger(messengerName))
    } else {
      dispatch({
        type: 'SET_MESSENGER_VALUE',
        value: {
          messengerName,
          value
        }
      })
    }
    dispatch(patchOrRemoveWidgetsData())
  }

export const setMessengerActiveState =
  (messengerName: string, isActive: boolean) => dispatch => {
    dispatch({
      type: 'SET_MESSENGER_ACTIVE_STATE',
      value: {
        messengerName,
        isActive
      }
    })
    dispatch(patchOrRemoveWidgetsData())
  }

export const setMessengerData =
  (messengerName: string, data: object) => dispatch => {
    dispatch({
      type: 'SET_MESSENGER_DATA',
      value: {
        messengerName,
        data
      }
    })
    dispatch(patchOrRemoveWidgetsData())
  }

const removeChat = (chatName: string) => ({
  type: 'REMOVE_CHAT',
  value: chatName
})

export const setChatKey = (chatName: string, key: string) => dispatch => {
  if (!key) {
    dispatch(removeChat(chatName))
  } else {
    dispatch({
      type: 'SET_CHAT_KEY',
      value: {
        chatName,
        key
      }
    })
  }
  dispatch(patchOrRemoveWidgetsData())
}

export const setChatPrimaryState = (chatName, isPrimary) => dispatch => {
  dispatch({
    type: 'SET_CHAT_PRIMARY_STATE',
    value: {
      chatName,
      isPrimary
    }
  })
  dispatch(patchOrRemoveWidgetsData())
}
