import styled from 'styled-components'
import { styleByLang } from '@editor/common/utils/style-by-lang'
import { getDisabledProps } from '../../../../../styled'

const buttonCustomStyle = {
  buttonsDirection: 'column',
  buttonMarginBottom: '10px',
  marginTop: '15px',
  sizeRecommendedMaxWidth: '185px'
}

const stylesObj = {
  ru: {
    ...buttonCustomStyle,
    imgWidth: '100px',
    imgHeight: '100px'
  },
  fr: buttonCustomStyle,
  pt: buttonCustomStyle,
  de: buttonCustomStyle,
  es: buttonCustomStyle,
  tr: buttonCustomStyle,
  default: {
    imgWidth: '100px',
    imgHeight: '100px'
  }
}
const getStylesByLang = styleByLang(stylesObj)

export const ImageChooserContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ImageWrapper = styled.div`
  width: 100px;
  height: 100px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
  }
`

export const Image = styled.img`
  width: ${getStylesByLang('imgWidth')};
  height: ${getStylesByLang('imgHeight')};
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`

export const ImageActions = styled.div`
  margin-left: 15px;
  margin-top: ${getStylesByLang('marginTop')};
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: ${getStylesByLang('buttonsDirection')};
`

export const Button = styled.button`
  ${getDisabledProps};
  display: flex;
  align-items: center;
  background: #eef5ff;
  color: #387dff;
  padding: 8px 15px 8px 10px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  width: fit-content;
  margin-right: 10px;
  margin-bottom: ${getStylesByLang('buttonMarginBottom')};

  .edit-icon {
    width: 16px;
    height: 16px;
  }

  svg {
    fill: #387dff;
    margin-right: 5px;
  }

  &:hover {
    svg {
      fill: #ffffff;
    }
    color: #ffffff;
    background: transparent linear-gradient(180deg, #5690ff 0%, #387dff 100%);
    transition: all 0.2s ease-out;
  }
`

export const SecondaryButton = styled(Button)`
  background: #ffffff;
  &:hover {
    svg {
      fill: #387dff;
    }
    color: #387dff;
    background: #eef5ff;
  }
`

export const SizeRecommendationText = styled.p`
  padding-top: 10px;
  font-size: 12px;
  color: #545f7e;
  user-select: none;
  max-width: ${getStylesByLang('sizeRecommendedMaxWidth')};
`
