import React, { memo, useState, useRef, useEffect, useCallback } from 'react'

import * as S from './styled'

export default memo(
  ({
    data,
    activeTab,
    setActiveTab,
    isActiveLineVisible = true,
    className = ''
  }) => {
    const [activeLineLeft, setActiveLineLeft] = useState(0)
    const [activeLineWidth, setActiveLineWidth] = useState(0)
    const [hasTransition, setTransitionState] = useState(false)
    const activeTabRef = useRef(null)

    useEffect(() => {
      const activeItem = activeTabRef.current

      if (!activeItem) return

      setActiveLineLeft(activeItem.offsetLeft)
      setActiveLineWidth(activeItem.clientWidth)
    }, [activeTabRef, activeTab])

    const onItemClick = useCallback(
      value => {
        setActiveTab(value)
        setTransitionState(true)
      },
      [setActiveTab]
    )

    return (
      <S.TabsContainer role="tablist" className={className}>
        {data.map(({ label, value, hasErrorInTab }) => {
          const isActive = activeTab === value

          return (
            <S.TabItem
              key={value}
              role="tab"
              ref={isActive ? activeTabRef : null}
              className="tab-item"
              id={value}
              isActive={isActive}
              isActiveLineVisible={isActiveLineVisible}
              onClick={() => {
                onItemClick(value)
              }}
            >
              <S.TabItemTitle className="tab-item-title" isActive={isActive}>
                {label}
                {hasErrorInTab && !isActive ? <S.AttentionIcon /> : null}
              </S.TabItemTitle>
            </S.TabItem>
          )
        })}
        {isActiveLineVisible ? (
          <S.ActiveLine
            lineWidth={activeLineWidth}
            lineLeft={activeLineLeft}
            className="active-line"
            hasTransition={hasTransition}
          />
        ) : null}
      </S.TabsContainer>
    )
  }
)
