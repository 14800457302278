export const capitalizeFirstLetter = str => {
  if (str === 'whatsapp') {
    return 'WhatsApp'
  }

  if (str === 'facebook') {
    return 'Messenger'
  }

  return str.charAt(0).toUpperCase() + str.slice(1)
}
