// @flow
import React, { memo } from 'react'

import type { TChooserFlyoutMobileProps } from './types.js'
import SlideUp from '@editor/common/lib/SlideUp'

const ChooserFlyoutMobile = ({
  isOpen,
  children,
  onClose
}: TChooserFlyoutMobileProps) => {
  return (
    <SlideUp isOpen={isOpen} onClose={onClose}>
      {children}
    </SlideUp>
  )
}

export default memo(ChooserFlyoutMobile)
