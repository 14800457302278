import styled, { createGlobalStyle } from 'styled-components'

import { Button } from '../../styled'

export const GlobalStyles = createGlobalStyle`
  .button-tooltip {
    flex-grow: 1;
    button {
      width: 100%;
    }
  }
  .button-tooltip_content {
    p {
      max-width: 240px;
    }
  }
  `

export const InputContainer = styled.div`
  position: relative;
  padding-top: 30px;
`

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  .input-container {
    margin-right: 10px;
  }
  .need-help-icon {
    width: 20px;
    height: 18px;
    fill: #7683a8;
    cursor: pointer;
    &:hover {
      fill: #387dff;
    }
  }

  .remove-icon {
    &:hover {
      svg {
        fill: #545f7e;
      }
    }
  }
`

export const SecondaryButton = styled(Button)`
  background: #ffffff;
  &:hover {
    color: #387dff;
    background: #eef5ff;
  }
`
