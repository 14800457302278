// @flow

import React, { useEffect, useState, memo, useContext, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { translate } from '@editor/common/utils/translations'
import { transformTextVariables } from '@src/editor/common/utils/text-transformation'
import * as Styled from './styled'
import { capitalizeFirstLetter } from './utils'
import type { TCustomizeWindowProps } from './types'
import EditingForm from './EditingForm'
import { MobileDetectContext } from '@contexts'
import { RfLoader } from '@editor/common/components/Loaders'
import SuccessState from '../../../shared-components/SuccessState'

const CustomizeWindow = ({
  isOpen,
  isSaving,
  showSuccess,
  type,
  data = {},
  onClose,
  setSuccessState
}: TCustomizeWindowProps) => {
  const [isMounted, setMounted] = useState(false)
  const isMobile = useContext(MobileDetectContext)

  useEffect(() => {
    if (isOpen) {
      setMounted(true)
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen && !isSaving) {
      setSuccessState(true)
      setTimeout(() => {
        setSuccessState(false)
        onClose()
      }, 2000)
    }
  }, [isSaving])

  const onAnimationEnd = () => {
    if (!isOpen) {
      setMounted(false)
    }
  }

  const customizeWindow = useMemo(
    () => (
      <Styled.CustomizeWindow isOpen={isOpen} onAnimationEnd={onAnimationEnd}>
        <Styled.Header>
          {transformTextVariables(translate('customize_chat_window_label'), [
            { value: capitalizeFirstLetter(type) }
          ])}
        </Styled.Header>
        <EditingForm
          type={type}
          data={data}
          isSaving={isSaving}
          onClose={onClose}
        />
      </Styled.CustomizeWindow>
    ),
    [isOpen, type, data, isSaving, onClose]
  )

  return isMounted
    ? isMobile
      ? createPortal(
          <Styled.MobileCustomizeWindowWrapper>
            {customizeWindow}
            <RfLoader
              isVisible={isSaving}
              text={translate('saving_your_changes_label')}
              className="saving-loader"
            />
            {showSuccess ? <SuccessState /> : null}
          </Styled.MobileCustomizeWindowWrapper>,
          document.body
        )
      : customizeWindow
    : null
}

export default memo(CustomizeWindow)
