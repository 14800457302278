// @flow
import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'
import { transformTextVariables } from '@src/editor/common/utils/text-transformation'

import {
  Card,
  Title,
  Label,
  Status,
  LogoCard,
  CardWrapper,
  MainContent,
  InputWrapper
} from '../../../styled'
import type { TMessengerItemProps } from './types'
import Buttons from './Buttons'

import { setMessengerValue, setMessengerActiveState } from '@actions/widgets'
import { MESSENGER_DEFAULT_SETTINGS } from './consts'

const MessengerItem = ({
  meta,
  settings = MESSENGER_DEFAULT_SETTINGS,
  setMessengerType,
  openCustomizeWindow,
  setMessengerValue,
  setMessengerActiveState
}: TMessengerItemProps) => {
  const { type, title, logo, inputLabel, Input, validation, maxCharLimit } =
    meta

  const [_value, setValue] = useState(settings.value)
  const [maxCharLimitMessage, setMaxCharLimitMessage] = useState('')
  const [validationMessage, setValidationMessage] = useState('')

  const hasChange = settings.value !== _value
  const isAddedToWebsite = settings.isActive

  const onInputChange = useCallback(value => {
    if (maxCharLimit && value.length > maxCharLimit) {
      const maxCharLimitMessage = transformTextVariables(
        translate('max_char_message'),
        [{ value: maxCharLimit }]
      )
      setMaxCharLimitMessage(maxCharLimitMessage)
      return
    }
    setValidationMessage('')
    setMaxCharLimitMessage('')
    setValue(value)
  }, [])

  const onInputBlur = useCallback(() => {
    maxCharLimitMessage && setMaxCharLimitMessage('')
    if (!hasChange) {
      return
    }

    if (!_value) {
      setMessengerValue(type, '')
    }
  }, [_value, type, hasChange, maxCharLimitMessage])

  const onConnect = useCallback(() => {
    if (!hasChange) {
      return
    }

    if (_value && validation) {
      const { message, validate } = validation

      if (!validate(_value)) {
        setValidationMessage(message)
        return
      }
    }

    setMessengerValue(type, _value)
  }, [_value, hasChange, type])

  const onRemoveFromWebsite = useCallback(() => {
    setMessengerActiveState(type, false)
  }, [type])

  const onAddToWebsite = useCallback(() => {
    setMessengerActiveState(type, true)
  }, [type])

  const onRemoveIconClick = useCallback(() => {
    setValidationMessage('')
    setValue('')
  }, [])

  const onKeyPress = e => {
    if (e.key === 'Enter') {
      onConnect()
      e.target.blur()
    }
  }

  return (
    <>
      <CardWrapper>
        {settings.value ? (
          <Status isActive={isAddedToWebsite}>
            <div className="status-circle" />
            {isAddedToWebsite
              ? translate('active_label')
              : translate('connected_label')}
          </Status>
        ) : null}
        <Card>
          <MainContent>
            <Title>{title}</Title>
            <InputWrapper>
              <Label>{inputLabel}</Label>
              <Input
                value={_value}
                error={maxCharLimitMessage || validationMessage}
                onRemoveIconClick={onRemoveIconClick}
                onChange={onInputChange}
                onBlur={onInputBlur}
                onKeyPress={onKeyPress}
              />
            </InputWrapper>
          </MainContent>
          <Buttons
            hasChange={hasChange}
            isConnected={!!settings.value}
            disabled={!_value}
            messengerType={type}
            isAddedToWebsite={isAddedToWebsite}
            setMessengerType={setMessengerType}
            onConnect={onConnect}
            onAddToWebsite={onAddToWebsite}
            openCustomizeWindow={openCustomizeWindow}
            onRemoveFromWebsite={onRemoveFromWebsite}
          />
        </Card>
        <LogoCard>
          <img src={logo} alt="messenger" />
        </LogoCard>
      </CardWrapper>
    </>
  )
}

const mapDispatchToProps = {
  setMessengerValue,
  setMessengerActiveState
}

export default connect(null, mapDispatchToProps)(MessengerItem)
