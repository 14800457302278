import styled, { css } from 'styled-components'
import { styleByLang } from '@editor/common/utils/style-by-lang'

const cardCustomStyle = {
  marginBottom: '20px'
}
const btnSectionPaddingStyle = {
  btnSectionPadding: '10px 20px'
}
const stylesObj = {
  pt: cardCustomStyle,
  ru: { ...cardCustomStyle, ...btnSectionPaddingStyle },
  fr: { ...cardCustomStyle, ...btnSectionPaddingStyle },
  de: {
    ...cardCustomStyle,
    ...btnSectionPaddingStyle,
    btnPadding: '5px 10px'
  },
  jp: {
    ...cardCustomStyle,
    ...btnSectionPaddingStyle,
    cardHeight: '285px'
  },
  es: { ...cardCustomStyle, ...btnSectionPaddingStyle },
  tr: { ...cardCustomStyle, ...btnSectionPaddingStyle },
  default: {
    marginBottom: 0,
    btnPadding: '11px 10px',
    btnSectionPadding: '15px 20px',
    cardHeight: '263px'
  }
}
const getStylesByLang = styleByLang(stylesObj)

export const getDisabledProps = ({ disabled }) =>
  disabled
    ? css`
        opacity: 0.5;
        box-shadow: none;
        cursor: default;
        pointer-events: none;
      `
    : ``

export const getButtonStyles = () => css`
  color: #387dff;
  padding: ${getStylesByLang('btnPadding')};
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  border: none;
  outline: none;
  cursor: pointer;
`

export const CardWrapper = styled.div`
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  &:nth-child(odd) {
    margin-right: 20px;
  }

  @media only screen and (max-width: 767px) {
    &:nth-child(odd) {
      margin-right: 0;
    }
  }
`

export const Card = styled.div`
  width: 350px;
  height: ${getStylesByLang('cardHeight')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #dee3f0;
  box-sizing: border-box;
`

export const LogoCard = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  top: -20px;
  left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #dee3f0;
  box-sizing: border-box;
  img {
    width: 30px;
    height: 30px;
  }
`

export const Title = styled.p`
  font-size: 16px;
  color: #363f5a;
  font-weight: 500;
`

export const MainContent = styled.div`
  margin: 50px 20px 0;
  margin-bottom: ${getStylesByLang('marginBottom')};
`

export const InputWrapper = styled.div`
  padding-top: 30px;
`

export const Label = styled.p`
  font-size: 14px;
  color: #545f7e;
  margin-bottom: 5px;
`

export const Status = styled.div`
  position: absolute;
  top: 15px;
  right: 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  color: ${({ isActive }) => (isActive ? '#0DA081' : '#DDAC0A')};

  .status-circle {
    position: relative;
    width: 14px;
    height: 14px;
    margin-right: 10px;
    border-radius: 50%;
    background: ${({ isActive }) => (isActive ? '#12BE994D' : '#DDAC0A4D')};
    &::after {
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background: ${({ isActive }) =>
        isActive
          ? 'transparent linear-gradient(180deg, #40ea95 0%, #17d677 52%, #0da081 100%) 0% 0%'
          : 'transparent linear-gradient(180deg, #F8D005 0%, #F4CA07 53%, #DDAC0A 100%) 0% 0%'};
    }
  }
`

export const ActionButtons = styled.div`
  display: flex;
  height: 70px;
  border-top: 1px solid #dee3f0;
  box-sizing: border-box;
  padding: ${getStylesByLang('btnSectionPadding')};
`

export const Button = styled.button`
  ${getButtonStyles};
  ${getDisabledProps};
  background: #eef5ff;
  min-width: 140px;
  padding: 11px 10px;
  &:hover {
    color: #ffffff;
    background: transparent linear-gradient(180deg, #5690ff 0%, #387dff 100%) 0%
      0%;
    transition: all 0.2s ease-out;
  }
`

export const ButtonWithIcon = styled.button`
  ${getButtonStyles};
  ${getDisabledProps};
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
  svg {
    fill: #387dff;
    margin-right: 5px;
  }
  &:hover {
    background: #eef5ff;
    transition: all 0.2s ease-out;
  }
`
