import styled from 'styled-components'
import { getDisabledProps, getButtonStyles } from '../../../../styled'
import { scrollBarStyles } from '@editor/common/styles'

export const EditingForm = styled.div`
  ${scrollBarStyles};
  padding: 30px 20px;
  box-sizing: border-box;
  overflow-y: scroll;
  @media only screen and (max-width: 768px) {
    padding: 20px 0 0;
  }
`

export const Containers = styled.div`
  display: flex;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const Container = styled.div`
  flex: 1;
  @media only screen and (max-width: 768px) {
    &:nth-child(1) {
      padding: 0 20px 20px;
    }
  }
`

export const PreviewContainer = styled.div`
  height: 100%;
  background-color: #f8fbff;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  @media only screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    border-radius: 0;
    padding: 30px 20px;
    & > div > div > div > div {
      width: 85vw;
    }
  }
`

export const FormWrapper = styled.div`
  padding: 15px 20px 0 10px;
`

export const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
  & label {
    margin: 0;
    color: #545f7e;
  }
  & textarea {
    resize: none;
  }
  & > div {
    position: relative;
    .message-text {
      position: absolute;
      bottom: -22px;
      font-size: 10px;
    }
  }
  @media only screen and (max-width: 768px) {
    & input,
    & textarea {
      font-size: 16px;
    }
  }
`

export const Button = styled.button`
  ${getDisabledProps};
  ${getButtonStyles};
  padding: 10px 30px;
  flex-grow: 0;
  background: transparent linear-gradient(180deg, #5690ff 0%, #387dff 100%);
  color: #ffffff;
  margin-right: 20px;
  &:hover {
    box-shadow: #387dff80;
    background: transparent linear-gradient(180deg, #6ea0ff 0%, #518dff 100%);
    transition: all 0.2s ease-out;
  }
`

export const SecondaryButton = styled.button`
  ${getButtonStyles};
  padding: 11px 30px;
  flex-grow: 0;
  background: #ffffff;
  color: #387dff;
  &:hover {
    background: #eef5ff;
    transition: all 0.2s ease-out;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  z-index: 1;
  box-shadow: 0px -3px 12px #0052e01a;
  padding: 10px 0 10px 30px;
  margin-top: auto;
  box-sizing: border-box;
  @media only screen and (max-width: 768px) {
    justify-content: center;
    padding: 10px 0;
  }
`
