import styled, { css } from 'styled-components'
import Popup from '@renderforest/rf-ui-library/organism/Popup'
import { scrollBarStyles } from '@editor/common/styles'

export const WidgetsPopup = styled(Popup)`
  z-index: 50;
  height: 100%;
  & > div {
    padding: 0;
    max-height: 90%;
    & > div {
      padding: 30px 0 0;
    }
  }
  .saving-loader {
    p {
      font-size: 16px;
      color: #545f7e;
    }
  }

  @media only screen and (max-width: 768px) {
    & > div {
      max-height: 100%;
      max-width: 100%;
      height: 100%;
      width: 100%;
    }
  }
`

export const Title = styled.p`
  color: #545f7e;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  padding-bottom: 15px;
`

export const TabsWrapper = styled.div`
  padding: 0 30px;
  border-bottom: 1px solid #dee3f0;
  ul {
    li {
      div:nth-child(1) {
        font-size: 14px;
        margin: 15px 10px 5px;
      }
      div:nth-child(2) {
        height: 5px;
      }
    }
    li:not(:first-child) {
      margin-left: 15px;
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 0 0 0 20px;
    ul {
      justify-content: flex-start;
    }
  }
`

export const Content = styled.div`
  ${scrollBarStyles}
  max-height: 675px;
  background-color: #f8fbff;
  padding: 30px 30px 10px;
  overflow-y: scroll;

  @media only screen and (max-width: 767px) {
    max-height: none;
    padding: 30px 0 10px;
  }

  @media only screen and (min-width: 768px) and (max-width: 889px) {
    max-height: none;
    padding: 30px 20px 10px;
  }
`

export const MainContent = styled.div`
  width: 720px;
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`

export const ContentTitle = styled.p`
  font-size: 20px;
  font-weight: 500;
  color: #363f5a;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;

  @media only screen and (max-width: 768px) {
    padding: 0 20px;
  }
`
