import React from 'react'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import SuccessSvg from '@website/common/assets/saved-icon.svg'

const SuccessState = () => {
  return (
    <Styled.SuccessStateContainer>
      <img src={SuccessSvg} alt="saved" />
      <p>{translate('saved_label')}</p>
    </Styled.SuccessStateContainer>
  )
}

export default SuccessState
