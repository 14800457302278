import styled, { css } from 'styled-components'
import {
  warningColor,
  warningBgColor,
  secondary
} from '@website/common/styles/colors'

const STYLES_BY_TYPE = {
  info: css`
    background: #cfe0ff1a;
    border: 1px solid #252e481a;

    & > svg {
      fill: ${secondary.SemiDark};
    }
  `,
  warning: css`
    background-color: ${warningBgColor};
    & > svg {
      fill: ${warningColor};
      & > * {
        fill: ${warningColor};
      }
    }
  `,
  error: css``
}

const getStylesByType = ({ type }) => STYLES_BY_TYPE[type]

export const NotificationMessageWrapper = styled.div`
  ${getStylesByType};
  & > svg {
    margin-right: 10px;
    flex-shrink: 0;
  }
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  padding: 10px 15px;
  color: ${secondary.SemiDark};
  border: 1px solid ${secondary.ExtraLight};
  border-radius: 5px;
`

export const Message = styled.p`
  font-size: 14px;
  line-height: 18px;
  text-align: left;
`
