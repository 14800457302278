import styled, { css, createGlobalStyle } from 'styled-components'

import Icon from '@renderforest/rf-ui-library/atoms/Icon'

export const GlobalStyles = createGlobalStyle`
  .chooser-flyout_content {
    transform: translateX(-20px);
    padding: 0;
    & > p {
      padding: 0;
    }
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
`

export const IconButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #dfe3ef;
    @media only screen and (max-width: 768px) {
      border-bottom: 1px solid #d2e0fc;
    }
  }
  & * {
    ${({ hasOtherStyle }) =>
      hasOtherStyle
        ? css`
            color: #387dff;
            fill: #387dff;
          `
        : css`
            color: #363f5a;
            fill: #363f5a;
          `}
  }
  @media only screen and (max-width: 768px) {
    padding: 20px;
  }
  & :hover {
    background-color: #edf5ff;
    * {
      ${({ hasOtherStyle }) =>
        hasOtherStyle
          ? css`
              color: #387dff;
              fill: #387dff;
            `
          : css`
              color: #363f5a;
              fill: #363f5a;
            `}
    }
  }
`

export const FlyOutContent = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #dfe3ef;
  box-sizing: border-box;
  @media only screen and (max-width: 768px) {
    border: none;
  }
`

export const FlyOutContentTitle = styled.div`
  color: #545f7e;
  font-size: 14px;
  font-weight: 500;
  padding: 20px;
  border-bottom: 1px solid #d2e0fc;
`

export const ButtonText = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin-left: 14px;
`

export const CloseButton = styled(Icon)`
  position: absolute;
  top: 20px;
  right: 15px;
`
