import styled, { css } from 'styled-components'

import { secondary, accentRed } from '@website/common/styles/colors'
import AttentionSvg from '@editor/common/assets/svgr-icons/Attention_icon_red.svg'

export const getTabItemColor = ({ isActive }) =>
  isActive ? '#3271e6' : secondary.SemiDark

export const TabsContainer = styled.ul`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  flex-grow: 1;
  list-style-type: none;
  justify-content: flex-start;
  flex-wrap: wrap;
  @media only screen and (max-width: 768px) {
    justify-content: space-between;
  }
`

export const TabItem = styled.li`
  cursor: pointer;
  flex: none;
  margin-bottom: ${({ isActiveLineVisible }) =>
    isActiveLineVisible ? '5px' : '0'};
`

export const TabItemTitle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 15px 10px;
  font-size: 16px;
  font-weight: 500;
  color: ${getTabItemColor};
`

export const ActiveLine = styled.div`
  position: absolute;
  background-color: #3271e6;
  width: ${({ lineWidth }) => lineWidth}px;
  height: 5px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  left: ${({ lineLeft }) => lineLeft}px;
  bottom: 0;
  ${({ hasTransition }) =>
    hasTransition
      ? css`
          transition: all 250ms ease;
        `
      : ''}
`

export const AttentionIcon = styled(AttentionSvg)`
  width: 14px;
  height: 14px;
  position: absolute;
  right: -20px;

  & * {
    fill: ${accentRed};
  }
`
