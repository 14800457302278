// @flow

import React, { useCallback, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import IconButton from './IconButton'
import { MediaLibContext, MobileDetectContext } from '@contexts'
import { openLoginPopup, setImageUploadInfo } from '@actions/editor'
import { selectIsUserAuthorized } from '@selectors'
import ChooserFlyoutMobile from './ChooserFlyoutMobile'
import { TooltipWrapper } from '@website/common/components/TooltipWrapper'
import MediaUploadButton from '@editor/common/components/MediaUploadButton'
import { generateUniqId, isBlobUrl } from '@editor/common/utils'
import { ImageUploadInfo } from '@editor/common/utils/image'
import { optimizeImage } from '@actions/utility'
import { messengerAvatarSizes } from '@editor/conf/imageSizes'

const ChooserFlyout = ({
  isOpen,
  onClose,
  onChoose,
  onRemove,
  children,
  isUserAuthorized,
  setImageUploadInfo
}) => {
  const { setMediaLibProps } = useContext(MediaLibContext)
  const isMobile = useContext(MobileDetectContext)
  const { siteId } = useParams()

  const stopPropagation = useCallback(e => {
    e.stopPropagation()
  }, [])

  const handleChoose = useCallback(
    (url: string) => {
      const uniqId = generateUniqId()
      const key = `unoptimized_${uniqId}`

      onClose()
      onChoose(key)
      setImageUploadInfo(
        key,
        new ImageUploadInfo({ failed: false, isLoading: true, url: url })
      )

      if (isBlobUrl(url)) {
        return
      }

      optimizeImage(url, messengerAvatarSizes, siteId)
        .then(({ imgDimensions }) => {
          setImageUploadInfo(
            key,
            new ImageUploadInfo({
              failed: false,
              isLoading: false,
              url: url
            })
          )
          onChoose(url, imgDimensions.small)
        })
        .catch(() => {
          setImageUploadInfo(
            key,
            new ImageUploadInfo({
              failed: true,
              isLoading: false,
              url: url
            })
          )
          onChoose(key)
        })
    },
    [onClose, onChoose]
  )

  const handleMediaLibClick = useCallback(() => {
    onClose()
    setMediaLibProps({
      action: { type: 'image', handler: handleChoose },
      mode: 'default',
      isMediaLibVisible: true
    })
  }, [onChoose, setMediaLibProps, onClose])

  const handleRemove = useCallback(() => {
    onRemove()
    onClose()
  }, [onRemove, onClose])

  const renderFlyOutContent = useCallback(
    (deviceIcon, deviceText) => (
      <Styled.FlyOutContent
        onClick={stopPropagation}
        onMouseDown={stopPropagation}
        onTouchStart={stopPropagation}
      >
        {isMobile ? (
          <Styled.FlyOutContentTitle>
            {translate('choose_an_image_label')}
          </Styled.FlyOutContentTitle>
        ) : null}
        <MediaUploadButton
          uploadType="image"
          className="device-chooser"
          insert={handleChoose}
        >
          <IconButton iconName={deviceIcon} text={deviceText} />
        </MediaUploadButton>
        <IconButton
          iconName="folder_opened"
          text={translate('choose_from_my_files_label')}
          onClick={handleMediaLibClick}
        />
        {onRemove ? (
          <IconButton
            iconName="deleteSvg"
            text={translate('remove_label')}
            hasOtherStyle
            onClick={handleRemove}
          />
        ) : null}
        {isMobile ? (
          <Styled.CloseButton
            name={translate('close_label')}
            color="secondarySemiLight"
            size="medium"
            onClick={onClose}
          />
        ) : null}
      </Styled.FlyOutContent>
    ),
    [onChoose, handleMediaLibClick, onRemove, isMobile, onClose]
  )

  return (
    <>
      <Styled.GlobalStyles />
      {isUserAuthorized ? (
        <TooltipWrapper
          className="chooser-flyout"
          withClick
          color="white"
          isOpen={isOpen}
          content={renderFlyOutContent(
            'upload',
            translate('upload_from_computer_label')
          )}
          position="bottom"
          offsets={{ y: 10, x: 80 }}
        >
          {children}
        </TooltipWrapper>
      ) : (
        children
      )}
      {isMobile ? (
        <ChooserFlyoutMobile onClose={onClose} isOpen={isOpen}>
          {renderFlyOutContent('upload', translate('upload_from_device'))}
        </ChooserFlyoutMobile>
      ) : null}
    </>
  )
}

const mapStateToProps = state => ({
  isUserAuthorized: selectIsUserAuthorized(state)
})

export default connect(mapStateToProps, { openLoginPopup, setImageUploadInfo })(
  ChooserFlyout
)
