import styled from 'styled-components'

export const TextInputWrapper = styled.div`
  display: flex;
  position: relative;
  .remove-icon {
    &:hover {
      svg {
        fill: #545f7e;
      }
    }
  }
`
